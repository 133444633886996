
import Typewriter from "typewriter-effect";
import '../css/pages.css';
import portrait from "../images/portrait.png"
import Project from "../Project";

function Portfolio() {
    function filterSelection(selection) {
        console.log(selection);
    }

    return (
        <div>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <div className={"intro"}>
                <img src={portrait} alt="photo of me" className = {"photo"}/>
                <div className = {"introwords"}>
                    <div className={"heading"}>
                        <Typewriter
                            onInit={(typewriter)=> {
                                typewriter
                                    .typeString("hello!")
                                    .pauseFor(500)
                                    .deleteAll()
                                    .typeString("heyoooooo~~~")
                                    .pauseFor(500)
                                    .deleteAll()
                                    .typeString("WELCOME!!!")
                                    .start();
                            }}
                        />
                    </div>
                    <br/>
                    Nice to meet you! My name is Xinru (pronounced "shin-rue") and I'm a magician (because I wave a stick as a conductor and
                    I build things get it hehe) from Chicago, IL. I'm currently in Seattle as a
                    a rotational product manager at <a href="https://www.metacareers.com/careerprograms/pathways/rpm" target="_blank" className={"pagelink"}>Meta</a> 
                    , and creating fun music experiences.
                    <br/><br/>
                    Check out some fun projects I worked on :D
                    <ul className={"bullet"} style={{ marginLeft: "-15px" }}>
                        <li>my music  <a href="https://xinruli.com/thesis/" target="_blank" class={"pagelink"}> honors thesis </a> that "deconstructed" an orchestra with motion sensors<a href="https://drive.google.com/file/d/1yM2g3-iDL0wq3-qZhfd9fRXZwSQbuG39/view?usp=sharing" target="_blank" class={"pagelink"}> (video) </a></li>
                        <li>a ~galaxy~ of <a href="https://xinruli.com/fiction1/" target="_blank" class={"pagelink"}> short stories </a> I wrote in college</li>
                        <li>my friend's dog <a href="https://www.tiktok.com/@beepbopviola/video/7209909703311183147?lang=en" target="_blank" class={"pagelink"}> "singing"</a> to Adele</li>

                    </ul>
                    <br/>
                    Ask me about...
                    <ul className={"bullet"} style={{ marginLeft: "-15px" }}>
                        <li>chaotic musical theater conducting stories</li>
                        <li>my most recent passion project/hobby</li>
                        <li>loose thoughts on art generation, the ~metaverse~, &amp; our place in the world</li>

                    </ul>
                </div>
            </div>

            <br/>
            {/* <div className={"projects"}> */}
                {/* <div className={"filter"}> */}
                    {/* <text className={"heading"}>My Projects!</text> */}
                    {/* <ul> */}
                        {/* <li class="btn active" onClick={filterSelection('all')} >All</li> */}
                        {/* <li>Software</li> */}
                        {/* <li>Engineering</li> */}
                        {/* <li>Product</li> */}
                        {/* <li>Design</li> */}
                        {/* <li>DEI</li> */}
                        {/* <li>Music</li> */}
                    {/* </ul> */}
                {/* </div> */}
                {/* <div className={"myprojects"}> */}
                    {/* <Project/> */}
                {/* </div> */}

            {/* </div> */}
        </div>
    );
}
export default Portfolio;